export const initCenterNotification = (notification = {}) => ({
    ...notification,

    _files: [...(notification._files || [])],
    files: [...(notification.files || [])],

    sites: [...(notification.sites || [])],

    subject: notification.subject ?? null,
    content: notification.content ?? null,

    shows: notification.shows ?? false,
    isNotice: notification.isNotice ?? false,

    viewCount: notification.viewCount ?? 0,

    createdAt: notification.createdAt ?? Date.now().toDateTime(),
});
