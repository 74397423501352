export const FormRoutes = [
    {
        // 관리자 - 폼메일관리
        path: "/console/forms",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/form/FormList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 폼메일관리
        path: "/console/forms/:_form",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/form/FormView.vue"),
        props: true,
        scope: ["console"],
    },
];
