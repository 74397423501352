import areas from "./areas";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import common from "./common";
import fairs from "./fairs";
import forms from "./forms";
import me from "./me";
import message from "./message";
import popups from "./popups";
import programs from "./programs";
import rental from "./rental";
import services from "./services";
import setting from "./setting";
import shop from "./shop";
import terms from "./terms";
import users from "./users";
import manage from "./manage";

export default {
    areas,
    banners,
    boards,
    center,
    common,
    fairs,
    forms,
    me,
    message,
    popups,
    programs,
    rental,
    services,
    setting,
    shop,
    terms,
    users,
    manage
};
