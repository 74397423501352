var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-people"
  }, [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "ma-n20"
  }, [_c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "md": "6",
      "xl": "5",
      "order": "2",
      "order-lg": "1"
    }
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('div', {
      key: item.name,
      staticClass: "swiper-slide"
    }, [_c('v-card', {
      staticClass: "main-people-card",
      attrs: {
        "to": item.to
      },
      on: {
        "mouseenter": _vm.pauseSwiper,
        "mouseleave": _vm.resumeSwiper
      }
    }, [_c('img', {
      staticClass: "main-people-card__image",
      attrs: {
        "src": item.img
      }
    }), _c('div', {
      staticClass: "main-people-card__title tit tit--sm white-text"
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c('icon-link-arrow', {
      attrs: {
        "white": ""
      }
    })], 1)])], 1)];
  })], 2)])]), _c('v-col', {
    staticClass: "pa-20",
    attrs: {
      "cols": "12",
      "order": "1",
      "order-lg": "2"
    }
  }, [_c('div', {
    staticClass: "pt-20 pt-md-56"
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit tit--md font-size-lg-40 pointed-title pointed-title--secondary"
  }, [_vm._v("금산에 "), _c('strong', [_vm._v("활력을 불어넣는 사람들")]), _vm._v("을 소개합니다.")])]), _c('v-col', [_c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "tit tit--sm font-weight-medium d-flex align-center",
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" 더보기 "), _c('icon-link-arrow')], 1)], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }