<template>
    <div class="main-archive">
        <v-container class="container--lg">
            <v-row class="row--xl">
                <v-col cols="12" order="2" order-lg="1">
                    <v-row>
                        <template v-for="(item, index) in items">
                            <v-col cols="6" lg="3" :key="index">
                                <v-card flat :to="item.to" class="archive-card">
                                    <div class="archive-card__inner">
                                        <v-img :src="item.image" class="archive-card__image" />
                                    </div>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
                <v-col cols="12" order="1" order-lg="2">
                    <v-row align="center" class="row--sm">
                        <v-col class="d-lg-none" order="2">
                            <v-divider style="border-color: #000" />
                        </v-col>
                        <v-col cols="auto" lg="12" order="3" order-lg="1">
                            <router-link to="/" class="tit tit--sm font-weight-medium d-flex align-center justify-end">
                                더보기
                                <icon-link-arrow />
                            </router-link>
                        </v-col>
                        <v-col cols="12" order="1" order-lg="2">
                            <v-row align="center" class="row--xl">
                                <v-col class="d-none d-lg-block">
                                    <v-divider style="border-color: #000" />
                                </v-col>
                                <v-col cols="12" md="auto">
                                    <div class="tit tit--md font-size-lg-40 pointed-title pointed-title--secondary">금산의 새로운 바람, <strong>신활력 현장</strong>을 <strong>기록</strong>합니다.</div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import iconLinkArrow from "../icon/icon-link-arrow.vue";
export default {
    components: { iconLinkArrow },
    data: () => ({
        items: [
            {
                image: "/images/archive/archive-1.jpg",
                to: "/",
            },
            {
                image: "/images/archive/archive-2.jpg",
                to: "/",
            },
            {
                image: "/images/archive/archive-3.jpg",
                to: "/",
            },
            {
                image: "/images/archive/archive-4.jpg",
                to: "/",
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
.archive-card {
    max-width: 342px;
    margin: 0 auto;
    transition: all 0.3s;
    &__inner {
        border-radius: 12px !important;
        overflow: hidden;
        transition: all 0.3s;
    }
    &__image {
        border-radius: inherit;
        transition: all 0.3s;
    }
}
@media (min-width: 768px) {
    .archive-card {
        padding-top: 50px;
        > &__inner {
            border-radius: 16px !important;
        }
        &:hover {
            .archive-card {
                &__inner {
                    box-shadow: 10px 10px 32px 0 rgba(9, 20, 48, 0.1);
                    transform: translateY(-50px);
                }
                &__image {
                    transform: scale(1.2);
                }
            }
        }
    }
}
</style>