export const UserBansRoutes = [
    {
        // 관리자 - 사용금지 정보
        path: "/console/user-bans",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/user-bans/UserBanList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 사용금지 정보
        path: "/console/user-bans/:type",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/user-bans/UserBanList.vue"),
        props: true,
        scope: ["console"],
    },
];
