export const AttendanceRoutes = [
    {
        // 관리자 - 참석 목록
        path: "/console/attendances",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/common/AttendanceListPage.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 참석 목록
        path: "/console/attendances/:type",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/common/AttendanceListPage.vue"),
        props: true,
        scope: ["console"],
    },
];
