export const USER_TYPES = { INDIVIDUAL: "USER_INDIVIDUAL", ORGANIZATION: "USER_ORGANIZATION" };

export const INDIVIDUAL_KINDS = {
    SUPPORTER: { value: "SUPPORTER", text: "전자제조지원단" },
    STUDENT: { value: "STUDENT", text: "인력양성사업참여학생" },
    ELSE: { value: "ELSE", text: "기타" },
};

export const USER_BAN_TYPES = {
    RENTAL_EQUIPMENTS: { value: "RENTAL_EQUIPMENTS", text: "장비" },
    RENTAL_FACILITIES: { value: "RENTAL_FACILITIES", text: "시설" },
};
