<template>
    <v-card class="main-board-notice">
        <div class="tit d-flex align-center mb-12 mb-md-16">
            <v-img max-width="36" src="/images/icon/icon-notice.svg" class="main-board-notice__icon" />
            공지사항
        </div>

        <v-simple-table class="main-board-notice__table">
            <template #default>
                <thead>
                    <tr>
                        <th>제목</th>
                        <th>작성일</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="item in 4">
                        <tr :key="item">
                            <td class="text-start">게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.</td>
                            <td>0000-00-00</td>
                        </tr>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
export default {
    data: () => ({}),
};
</script>

<style lang="scss" scoped>
.main-board-notice {
    border-radius: 12px;
    box-shadow: 0 0 12px 0 rgba(9, 20, 48, 0.1) !important;
    padding: 18px;

    &__icon {
        margin-right: 4px;
    }

    &__table {
        thead {
            > tr {
                > th {
                    &:last-child {
                        width: 20%;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .main-board-notice {
        border-radius: 16px;
        padding: 24px 40px;
    }
}
</style>