<template>
    <div class="visual">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <img src="/images/main/visual.jpg" alt="" class="visual__image" />
                    <v-container class="container--lg">
                        <v-row align="center" class="row--lg">
                            <v-col cols="12" lg="auto">
                                <!-- Mobile -->
                                <div class="d-lg-none">
                                    <div class="pointed-title tit white--text font-weight-medium">
                                        금산에 불어오는 새로운 활력<br />
                                        이 곳은 <strong class="strong">금산살이</strong>입니다.
                                    </div>
                                </div>
                                <!-- // Mobile -->

                                <!-- PC -->
                                <div class="d-none d-lg-block">
                                    <div class="pointed-title tit tit--xl white--text font-weight-medium">
                                        금산에 불어오는 새로운 활력<br />
                                        이 곳은 <strong class="strong">금산살이</strong>입니다.
                                    </div>
                                </div>
                                <!-- // PC -->
                            </v-col>
                            <v-col cols="8" lg="">
                                <v-divider class="visaul__divider" />
                            </v-col>
                            <v-col cols="4" lg="auto">
                                <router-link to="/" class="tit tit--sm white--text d-flex align-center">
                                    소개 더보기
                                    <icon-link-arrow white />
                                </router-link>
                            </v-col>
                        </v-row>
                        <div class="swiper-pagination"></div>
                    </v-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLinkArrow from "@/components/client/icon/icon-link-arrow.vue";

export default {
    components: {
        IconLinkArrow,
    },
    props: {},
    data: () => ({}),
    computed: {},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.$nextTick(() => {
                var mainVisual = new Swiper(".visual .swiper-container", {
                    effect: "fade",
                    allowTouchMove: false,
                    spaceBetween: 0,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                    },
                    speed: 500,
                    loop: true,
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.visual {
    position: relative;
    height: 100%;
    min-height: 348px;
    max-width: 100%;
    aspect-ratio: 1920 / 980;
    &__image {
        aspect-ratio: 1920 / 980;
        min-width: 680px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }
    &__divider {
        border-width: 1px;
        border-color: #fff;
    }
    .swiper-container {
        height: inherit;
        .swiper-wrapper {
            height: inherit;
            .swiper-slide {
                height: inherit;
                display: flex;
                align-items: end;
                padding-bottom: 5%;
            }
        }
    }
    .swiper-pagination {
        position: static;
        justify-content: start;

        &-bullets {
            margin-top: 24px;
            margin-left: -6px;
            ::v-deep {
                .swiper-pagination-bullet {
                    margin: 0 6px;
                    background-color: rgba(255, 255, 255, 0.4);
                    opacity: 1;
                    width: 8px;
                    height: 8px;
                    &-active {
                        width: 20px;
                        height: 20px;
                        border: 1px solid #fff;
                        background-color: transparent;
                        position: relative;
                        &::after {
                            content: "";
                            width: 8px;
                            height: 8px;
                            border-radius: inherit;
                            background-color: #fff;
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 1440px) {
    .visual {
        aspect-ratio: initial;
    }
}
</style>