import Vue from "vue";
import VueRouter from "vue-router";

import axios from "@/plugins/axios";

import Main from "@/pages/client/Main.vue";
import { ConsoleRoutes } from "./console";

Vue.use(VueRouter);

const routes = [
    ////////////////////////////////////////////////////////////////////////
    // 사용자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////
    {
        // Main 페이지
        path: "/",
        component: Main,
    },
    {
        path: "/common/kcp/cert/request",
        component: () => import("../pages/common/kcp/KCPCertRequest.vue"),
        props: true,
    },
    {
        path: "/common/kcp/cert/response",
        component: () => import("../pages/common/kcp/KCPCertResponse.vue"),
        props: true,
    },
    {
        // 로그인
        path: "/login",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/main.vue"),
        props: true,
    },
    {
        // 비밀번호 찾기
        path: "/login/find-password",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-password.vue"),
        props: true,
    },
    {
        // 아이디 찾기
        path: "/login/find-id",
        component: () => import(/* webpackChunkName: "login" */ "../pages/client/login/find-id.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/main.vue"),
        props: true,
    },
    {
        // 회원가입
        path: "/join/type",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/type.vue"),
        props: true,
    },
    {
        // 회원가입 폼
        path: "/join/form",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/form.vue"),
        props: true,
    },
    {
        // 회원가입 완료
        path: "/join/complete",
        component: () => import(/* webpackChunkName: "join" */ "../pages/client/join/complete.vue"),
        props: true,
    },

    {
        // 마이페이지 - 회원정보
        path: "/mypage/myinfo",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/myinfo.vue"),
        props: true,
    },
    {
        // 마이페이지 - 회원 탈퇴
        path: "/mypage/leave",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/leave.vue"),
        props: true,
    },
    {
        // 마이페이지 - 프로그램 신청 현황
        path: "/mypage/programs",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/programs/MypageProgramList.vue"),
        props: true,
    },
    {
        // 마이페이지 - 프로그램 신청 상세 (출석확인)
        path: "/mypage/programs/:_program",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/programs/MypageProgramView.vue"),
        props: true,
    },
    {
        // 마이페이지 - 설비 예약 현황
        path: "/mypage/rental-requests",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/rental/MypageRentalRequests.vue"),
        props: true,
    },
    {
        // 마이페이지 - 설비 예약 현황
        path: "/mypage/rental-requests/:subjectType",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/rental/MypageRentalRequests.vue"),
        props: true,
    },
    {
        // 마이페이지 - 불참기록
        path: "/mypage/bans",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/bans/MypageBanList.vue"),
        props: true,
    },
    {
        // 마이페이지 - 비밀번호 변경
        path: "/mypage/password",
        component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/password.vue"),
        props: true,
    },

    // {
    //     // 마이페이지 - 예약일정
    //     path: "/mypage/rental-schedules",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/RentalScheduleList.vue"),
    //     props: true,
    // },
    // {
    //     // 마이페이지 - 부품현황
    //     path: "/mypage/mes-materials",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MesMaterialsPage.vue"),
    //     props: true,
    // },
    // {
    //     // 마이페이지 -
    //     path: "/mypage/mes-dashboard",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/MesDashboardPage.vue"),
    //     props: true,
    // },
    // {
    //     // 마이페이지 - 지원서비스 목록
    //     path: "/mypage/applications",
    //     component: () => import(/* webpackChunkName: "mypage" */ "../pages/client/mypage/ApplicationPage.vue"),
    //     children: [...ApplicationRoutes],
    //     props: true,
    // },
    // {
    //     // 1:1 문의
    //     path: "/mypage/question",
    //     component: () => import(/* webpackChunkName: "question" */ "../pages/client/mypage/question.vue"),
    //     children: [...QuestionRoutes],
    //     props: true,
    // },

    {
        // 소개 - 인사말
        path: "/about/greetings",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/about/GreetingsPage.vue"),
        props: true,
    },
    {
        // 소개 - 사업소개
        path: "/about/business",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/about/BusinessPage.vue"),
        props: true,
    },
    {
        // 소개-공간소개
        path: "/about/facilities",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/FacilitiesPage.vue"),
        props: true,
    },
    {
        // 소개-입주현황
        path: "/about/current",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/CurrentPage.vue"),
        props: true,
    },
    {
        // 소개-이용안내
        path: "/about/information",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/about/InformationPage.vue"),
        props: true,
    },

    {
        // 프로그램-프로그램신청
        path: "/program/list",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/program/ProgramListPage.vue"),
        props: true,
    },
    {
        // 프로그램-프로그램신청
        path: "/program/list/:state",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/program/ProgramListPage.vue"),
        props: true,
    },
    {
        // 프로그램-프로그램신청 - 상세
        path: "/program/view/:_program",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/program/ProgramViewPage.vue"),
        props: true,
    },
    {
        // 프로그램-프로그램안내
        path: "/program/info",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/program/ProgramInfoPage.vue"),
        props: true,
    },

    {
        // 장비 및 공간 - 전문제조장비
        path: "/rental/intro",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/rental/RentalIntroPage.vue"),
        props: true,
    },
    {
        // 장비 및 공간 - 목록
        path: "/rental/:type",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/rental/RentalListPage.vue"),
        props: true,
    },
    {
        // 장비 및 공간 - 예약
        path: "/rental/:type/:_subject",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/rental/RentalFormPage.vue"),
        props: true,
    },

    {
        // 지원서비스-안내
        path: "/service/about",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceAboutPage.vue"),
        props: true,
    },
    {
        // 지원서비스-목록
        path: "/service/supports",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceSupportList.vue"),
        props: true,
    },
    {
        // 지원서비스-목록
        path: "/service/supports/:category",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceSupportList.vue"),
        props: true,
    },
    {
        // 지원서비스-상세
        path: "/service/supports/:category/:_program",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceSupportView.vue"),
        props: true,
    },
    {
        // 지원서비스-신청 양식
        path: "/service/supports/:category/:_program/apply",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceSupportInput.vue"),
        props: true,
    },
    {
        // 지원서비스-신청 완료
        path: "/service/supports/:category/:_program/complete",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceSupportComplete.vue"),
        props: true,
    },

    {
        // 프로그램 - 목록
        path: "/service/programs",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/service/ServiceProgramList.vue"),
        props: true,
    },
    {
        // 프로그램 - 목록
        path: "/service/programs/:_program",
        component: () => import(/* webpackChunkName: "form" */ "../pages/client/service/ServiceProgramView.vue"),
        props: true,
    },
    {
        // 프로그램 - 신청 양식
        path: "/service/programs/:_program/apply",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceProgramInput.vue"),
        props: true,
    },
    {
        // 프로그램 - 신청 완료
        path: "/service/programs/:_program/complete",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/service/ServiceProgramComplete.vue"),
        props: true,
    },

    {
        // 게시판-공지사항
        path: "/community/notice",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/NoticePage.vue"),
        props: true,
    },
    {
        // 게시판-공지사항
        path: "/community/notice/:_notification",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/NoticePage.vue"),
        props: true,
    },
    {
        // 게시판-건의합니다
        path: "/community/proposal",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/ProposalPage.vue"),
        props: true,
    },
    {
        // 게시판-건의합니다
        path: "/community/proposal/:_proposal",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/ProposalPage.vue"),
        props: true,
    },
    {
        // 게시판-1대1문의
        path: "/community/inquiry",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/InquiryPage.vue"),
        props: true,
    },

    // {
    //     // 커뮤니티-자료실
    //     path: "/community/reference",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/ReferencePage.vue"),
    //     props: true,
    // },
    // {
    //     // 커뮤니티-자료실
    //     path: "/community/reference/:_reference",
    //     component: () => import(/* webpackChunkName: "sub" */ "../pages/client/sub/community/ReferencePage.vue"),
    //     props: true,
    // },
    // {
    //     // 문의 및 제휴
    //     path: "/form/inquiry-partner",
    //     component: () => import(/* webpackChunkName: "form" */ "../pages/client/form/InquiryPartner.vue"),
    //     props: true,
    // },
    // {
    //     // 공지사항
    //     path: "/center/notice",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // 공지사항
    //     path: "/center/notice/:_notification",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/notice.vue"),
    //     props: true,
    // },
    // {
    //     // 자주묻는질문
    //     path: "/center/faqs",
    //     component: () => import(/* webpackChunkName: "center" */ "../pages/client/center/faq.vue"),
    //     props: true,
    // },
    {
        // 개인정보처리방침
        path: "/privacy",
        component: () => import(/* webpackChunkName: "sub" */ "../pages/client/center/PrivacyPolicyPage.vue"),
        props: true,
    },

    ////////////////////////////////////////////////////////////////////////
    // 관리자 페이지 라우트
    ////////////////////////////////////////////////////////////////////////
    ...ConsoleRoutes,
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,

    scrollBehavior: (to, from, savedPosition) => {
        let tabletWidth = 1200;
        let mobileWidth = 768;
        let subVisualHeight = 180;
        if (window.innerWidth <= tabletWidth && window.innerWidth > mobileWidth) {
            subVisualHeight = 300;
        }
        if (window.innerWidth > tabletWidth) {
            subVisualHeight = 450;
        }

        return new Promise((resolve) =>
            setTimeout(() => {
                if (to.hash) resolve({ selector: to.hash });

                if (savedPosition) {
                    resolve({ ...savedPosition });
                }

                if (to.path.includes("/mypage/")) resolve({ x: 0, y: subVisualHeight });

                if (to?.path == from?.path) {
                    if (to?.query?.page == from?.query?.page) resolve();
                }

                resolve({ x: 0, y: 0 });
            }, 10)
        );
    },
});

router.beforeEach((to, from, next) => {
    try {
        const accessToken = localStorage.getItem("accessToken");
        axios.defaults.headers.common["Authorization"] = !!accessToken ? `Bearer ${accessToken}` : "";

        const payload = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) || {} : {};
        const route = routes.find((route) => route.path == to.path);
        if (route?.scope) {
            if (!accessToken) throw new Error("로그인 후 이용가능합니다");

            const { scope = [] } = payload;
            if (!scope.some((scope) => route.scope.includes(scope))) throw new Error("접근권한이 없습니다");

            next();
        } else if (route?.userTypes) {
            if (!accessToken) throw new Error("로그인 후 이용가능합니다");

            const { userType } = payload;
            if (!route.userTypes.includes(userType)) throw new Error("접근권한이 없습니다");

            next();
        } else next();
    } catch (error) {
        alert(error.message);
        let path;
        if (from.path != "/") path = routes.find((route) => route.path == from.path);
        if (!path) {
            path = ["/mypage", "promoter", "/console"].find((path) => to.path.includes(path)) || "/";
        }
        window.location.href = path;
    }
});
export default router;
