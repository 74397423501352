var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "ma-n4 ma-md-n6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "disabled": ""
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.navigate.apply(null, arguments);
      }
    }
  }, [_vm.accessToken ? [_vm._v("로그아웃")] : [_vm._v("로그인")]], 2)], 1), _c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "login-divider"
  })]), _c('v-col', {
    staticClass: "pa-4 pa-md-6",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "to": _vm.accessToken ? '/join' : '/mypage',
      "text": "",
      "small": "",
      "disabled": ""
    }
  }, [_vm.accessToken ? [_vm._v("회원가입")] : [_vm._v("마이페이지")]], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }