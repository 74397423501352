<template>
    <div>
        <v-row justify="center" class="row--small">
            <template v-for="item in items">
                <v-col :key="item.name" cols="auto">
                    <v-btn icon small :href="item.link">
                        <v-img eager :src="item.icon" :alt="item.name" />
                    </v-btn>
                </v-col>
            </template>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                name: "인스타그램",
                icon: "/images/icon/icon-instagram.svg",
                link: "/",
            },
            {
                name: "블로그",
                icon: "/images/icon/icon-blog.svg",
                link: "/",
            },
            {
                name: "유튜브",
                icon: "/images/icon/icon-youtube.svg",
                link: "/",
            },
        ],
    }),
};
</script>