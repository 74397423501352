export const AboutRoutes = [
    {
        // 관리자 - 입주기업현황
        path: "/console/about/current",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/Current.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 입주기업현황
        path: "/console/about/current/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/CurrentView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 입주기업현황
        path: "/console/about/current/:_manage",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/CurrentView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 입주기업현황링크
        path: "/console/about/currentLink",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/Link.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 입주기업현황
        path: "/console/about/currentLink/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/LinkView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 입주기업현황
        path: "/console/about/currentLink/:_manage",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/LinkView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인링크
        path: "/console/about/main",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/Link.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인링크
        path: "/console/about/main/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/LinkView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 메인링크
        path: "/console/about/main/:_manage",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/LinkView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로그램 안내 링크
        path: "/console/about/program",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/Link.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로그램 안내 링크
        path: "/console/about/program/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/LinkView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로그램 안내 링크
        path: "/console/about/program/:_manage",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/LinkView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 이용안내
        path: "/console/about/info",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/Current.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 이용안내
        path: "/console/about/info/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/CurrentView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 이용안내
        path: "/console/about/info/:_manage",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/about/CurrentView.vue"),
        props: true,
        scope: ["console"],
    },
];
