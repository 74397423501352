var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "map-image",
    attrs: {
      "color": "transparent",
      "tile": "",
      "flat": "",
      "max-width": _vm.maxWidth
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.currentMapSrc,
      "alt": ""
    }
  }), _vm._l(_vm.mapData, function (item) {
    return [_c('v-btn', {
      key: item.code,
      style: `left: calc(${item.x} / 792 * 100%); top: calc(${item.y} / 898 * 100%)`,
      on: {
        "mouseenter": function ($event) {
          return _vm.changeMap(item.code);
        },
        "mouseleave": _vm.resetMap
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }