import axios from "@/plugins/axios";

let url = "/api/console/common/files";

export default {
    post(data = {}, file) {
        var headers = { "Content-Type": "multipart/form-data" };

        var formData = new FormData();

        const { path, index, _user, _notification } = data;

        if (path) formData.append("path", path);
        if (index !== undefined) formData.append("index", index);

        if (_user) formData.append("_user", _user);
        if (_notification) formData.append("_notification", _notification);

        formData.append("file", file);

        return axios.post(url, formData, { headers }).then((result) => result.data);
    },
    delete(data) {
        return axios.delete(`${url}/${data._id}`).then((result) => result.data);
    },
};
