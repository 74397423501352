export const BannerRoutes = [
    {
        // 관리자 - 배너관리
        path: "/console/banners",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/banner/BannerList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/create",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/banner/BannerView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 배너관리
        path: "/console/banners/:_banner",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/banner/BannerView.vue"),
        props: true,
        scope: ["console"],
    },
];
