import axios from "@/plugins/axios";

import areas from "./areas";
import banners from "./banners";
import boards from "./boards";
import center from "./center";
import common from "./common";
import counsels from "./counsels";
import fairs from "./fairs";
import forms from "./forms";
import levels from "./levels";
import mes from "./mes";
import messages from "./messages";
import popups from "./popups";
import programCategories from "./program-categories";
import programKeywords from "./program-keywords";
import programs from "./programs";
import rental from "./rental";
import services from "./services";
import setting from "./setting";
import shop from "./shop";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";
import manage from "./manage";
export default {
    areas,
    banners,
    boards,
    center,
    common,
    counsels,
    fairs,
    forms,
    levels,
    mes,
    messages,
    popups,
    programCategories,
    programKeywords,
    programs,
    rental,
    services,
    setting,
    shop,
    statistics,
    terms,
    users,
    manage,
    email: {
        post({ to, subject, html }) {
            return axios.post("/api/console/email", { to, subject, html }).then((result) => result.data);
        },
    },
    message: {
        post({ to, subject, message }) {
            return axios.post("/api/console/message", { to, subject, message }).then((result) => result.data);
        },
    },
};
