<template>
    <v-card color="transparent" tile flat :max-width="maxWidth" class="map-image">
        <img :src="currentMapSrc" alt="">
        <template v-for="item in mapData">
            <v-btn :key="item.code" :style="`left: calc(${item.x} / 792 * 100%); top: calc(${item.y} / 898 * 100%)`" @mouseenter="changeMap(item.code)" @mouseleave="resetMap">
                {{ item.name }}
            </v-btn>
        </template>
    </v-card>
</template>

<script>
import mapData from "@/assets/data/map-data.json";

export default {
    props: {
        maxWidth: { type: String, default: "792" },
        mainPage: { type: Boolean, default: "false" },
    },
    data: () => ({
        mapData,
        currentMapSrc: "/images/map/map-default.svg",
    }),
    methods: {
        changeMap(code) {
            if (code) {
                this.currentMapSrc = `/images/map/${code}.svg`;
            }
        },
        resetMap() {
            this.currentMapSrc = "/images/map/map-default.svg";
        },
    },
};
</script>

<style lang="scss" scoped>
.map-image {
    position: relative;
    aspect-ratio: 792/898;
    img {
        display: block;
    }
    .v-btn {
        transform: translate(-25%, -25%);
        position: absolute;
        min-width: initial !important;
        width: calc(120 / 792 * 100%) !important;
        height: calc(100 / 898 * 100%) !important;
        opacity: 0;
        a {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}
</style>