<template>
    <div class="main-map">
        <v-container class="container--lg">
            <v-row align="center">
                <v-col cols="12" md="6" order="2" order-lg="1">
                    <map-image max-width="510" mainPage class="main-map__image" />
                </v-col>
                <v-col cols="12" md="6"  order="3" order-lg="2">
                    <v-img max-width="342" src="/images/main/map/storybook.png" class="main-map__story-book"></v-img>
                    <router-link to="/" class="tit tit--sm font-weight-medium d-flex align-center justify-center my-16 my-md-20">
                        더보기
                        <icon-link-arrow />
                    </router-link>
                </v-col>
                <v-col cols="12" order="1" order-lg="3">
                    <v-row align="center" class="row--xl">
                        <v-col class="d-none d-md-block">
                            <v-divider style="border-color: #000" />
                        </v-col>
                        <v-col cols="12" md="auto">
                            <div class="tit tit--md text-center font-size-lg-40">
                                <div class="pointed-title">금산의 260개 마을이 궁금하신가요?</div>
                                <div>금산 <strong>신활력의 무대, 260 마을</strong>을 소개합니다.</div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import iconLinkArrow from "../icon/icon-link-arrow.vue";
import MapImage from '../map/map-image.vue';

export default {
    components: { iconLinkArrow, MapImage },
};
</script>

<style lang="scss" scoped>
.main-map {
    &__image {
        margin: 58px auto 0;
    }
    &__story-book {
        margin: 0 auto 0;
        border-radius: 12px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    }
}
@media (min-width: 1440px) {
    .main-map {
        padding-top: 58px;
        &__story-book {
            border-radius: 16px;
        }
    }
}
</style>