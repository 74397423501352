var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', {
    staticClass: "container--lg"
  }, [_c('v-row', {
    staticClass: "row--xl",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "main-place__card",
    attrs: {
      "flat": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/images/main/place/main-place.jpg"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "tit tit--md font-size-lg-40 pointed-title pointed-title--secondary"
  }, [_vm._v("금산 "), _c('strong', [_vm._v("우리동네 이음터")]), _vm._v(" 공간을 소개합니다.")])]), _c('v-col', [_c('v-divider', {
    staticStyle: {
      "border-color": "#000"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('router-link', {
    staticClass: "tit tit--sm font-weight-medium d-flex align-center",
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" 더보기 "), _c('icon-link-arrow')], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }