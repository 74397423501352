<template>
    <v-row align="center" class="ma-n4 ma-md-n6">
        <v-col cols="auto" class="pa-4 pa-md-6">
            <v-btn text small @click.native="navigate" disabled>
                <template v-if="accessToken">로그아웃</template>
                <template v-else>로그인</template>
            </v-btn>
        </v-col>
        <v-col cols="auto" class="pa-4 pa-md-6">
            <div class="login-divider" />
        </v-col>
        <v-col cols="auto" class="pa-4 pa-md-6">
            <v-btn :to="accessToken ? '/join' : '/mypage'" text small disabled>
                <template v-if="accessToken">회원가입</template>
                <template v-else>마이페이지</template>
            </v-btn>
        </v-col>
    </v-row>
</template>
<script>
export default {
    methods: {
        navigate() {
            if (this.accessToken) {
                this.$store.dispatch("logout");
            } else {
                this.$router.push(`/login`);
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-btn.v-btn--text.v-size--small {
        height: auto;
        padding: 6px;
        margin: -6px;
        font-size: 1.4rem;
        font-weight: 400;
    }
    .theme--light.v-btn--active:not(:hover):before {
        opacity: 0;
    }
    .theme--light.v-btn--active:hover:before {
        opacity: 0.04;
    }
}
.login-divider {
    width: 1px;
    height: 12px;
    background: var(--v-grey-base);
}
</style>