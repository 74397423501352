export const MessageRotues = [
    {
        // 문자/알림톡 셋업
        path: "/console/messages",
        name: "console-messages",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/messages/MessagesView.vue"),
        props: true,
        scope: ["console"],
    },
];
