export const ProgramRoutes = [
    {
        // 관리자 - 프로그램 목록
        path: "/console/programs",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/program/ProgramList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로그램 상세
        path: "/console/programs/:_program",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/program/ProgramView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로그램 상세
        path: "/console/programs/:_program/:tab",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/program/ProgramView.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 프로그램 카테고리 목록
        path: "/console/program-categories",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/program/ProgramCategoryList.vue"),
        props: true,
        scope: ["console"],
    },
];
