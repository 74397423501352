<template>
    <div>
        <v-container class="container--lg">
            <v-row align="center" class="row--xl">
                <v-col cols="12">
                    <v-card flat class="main-place__card">
                        <v-img src="/images/main/place/main-place.jpg"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="auto">
                    <div class="tit tit--md font-size-lg-40 pointed-title pointed-title--secondary">금산 <strong>우리동네 이음터</strong> 공간을 소개합니다.</div>
                </v-col>
                <v-col>
                    <v-divider style="border-color: #000" />
                </v-col>
                <v-col cols="auto">
                    <router-link to="/" class="tit tit--sm font-weight-medium d-flex align-center">
                        더보기
                        <icon-link-arrow />
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import iconLinkArrow from "../icon/icon-link-arrow.vue";
export default {
    components: { iconLinkArrow },
};
</script>

<style lang="scss" scoped>
.main-place {
    &__card {
        border-radius: 12px;
        box-shadow: 0 0 12px 0 rgba(9, 20, 48, 0.1) !important;
    }
}
</style>