export const CenterRoutes = [
    {
        // 관리자 - 공지사항
        path: "/console/center/notifications",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/center/CenterNotificationList.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - FAQ
        path: "/console/center/faqs",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/center/Faq.vue"),
        props: true,
        scope: ["console"],
    },
    {
        // 관리자 - 1:1문의
        path: "/console/center/questions",
        component: () => import(/* webpackChunkName: "console-app" */ "@/pages/console/center/Question.vue"),
        props: true,
        scope: ["console"],
    },
];
