var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "main-board-notice"
  }, [_c('div', {
    staticClass: "tit d-flex align-center mb-12 mb-md-16"
  }, [_c('v-img', {
    staticClass: "main-board-notice__icon",
    attrs: {
      "max-width": "36",
      "src": "/images/icon/icon-notice.svg"
    }
  }), _vm._v(" 공지사항 ")], 1), _c('v-simple-table', {
    staticClass: "main-board-notice__table",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('thead', [_c('tr', [_c('th', [_vm._v("제목")]), _c('th', [_vm._v("작성일")])])]), _c('tbody', [_vm._l(4, function (item) {
          return [_c('tr', {
            key: item
          }, [_c('td', {
            staticClass: "text-start"
          }, [_vm._v("게시물 제목이 옵니다. 게시물 제목이 옵니다. 게시물 제목이 옵니다.")]), _c('td', [_vm._v("0000-00-00")])])];
        })], 2)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }