import { INDIVIDUAL_KINDS, USER_TYPES } from "@/assets/variables";

export const initUserIndividual = (user = {}) => ({
    ...user,

    type: user.type ?? USER_TYPES.INDIVIDUAL,
    kind: user.kind ?? INDIVIDUAL_KINDS.ELSE.value,

    username: user.username ?? null,
    name: user.name ?? null,
    phone: user.phone ?? null,
    email: user.email ?? null,
    password: user.password ?? null,
    passwordConfirm: user.passwordConfirm ?? null,

    blacksmith: {
        ...(user.blacksmith || {}),

        _equipments__rentable: [...(user.blacksmith?._equipments__rentable ?? [])],
        equipments__rentable: [...(user.blacksmith?.equipments__rentable ?? [])],

        _equipmentUseConsent: user.blacksmith?._equipmentUseConsent ?? null,
        equipmentUseConsent: user.blacksmith?.equipmentUseConsent ?? null,

        insurance: {
            ...(user.blacksmith?.insurance || {}),

            policies: [...(user.blacksmith?.insurance?.policies || [])],
            expiresAt: user.blacksmith?.insurance?.expiresAt?.toDate?.() ?? null,
        },

        facility: {
            ...(user.blacksmith?.facility || {}),
            isActive: user.blacksmith?.facility?.isActive ?? false,
        },
    },
});

export const initUserOrganization = (user = {}) => ({
    ...initUserIndividual(user),

    type: user.type ?? USER_TYPES.ORGANIZATION,
    kind: undefined,

    companyName: user.companyName ?? null,
    businessNumber: user.businessNumber ?? null,

    _businessRegistration: user._businessRegistration ?? null,
    businessRegistration: user.businessRegistration ?? null,
});
